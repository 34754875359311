<template>
    <div class="wrap" v-if="visible">
        <div class="main">
            <div class="title-1">Please choose</div>
            <div class="title-2">your preferred language</div>
            <div class="buttons">
                <div class="button" @click="changeLanguage('eng')">English</div>
                <div class="button" @click="changeLanguage('chn')">简体中文</div>
                <div class="button" @click="changeLanguage('kor')">한국어</div>
            </div>
            <footer>Powered by minitable</footer>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';

export default {
    name: 'ChooseLang',
    components: {},
    data() {
        return {
            visible: false
        };
    },
    computed: {
        ...mapGetters({
            language: 'app/get_language'
        }),
    },
    created() {},
    methods: {
        ...mapMutations({
            set_language: 'app/APP_SET_LANGUAGE'
        }),
        changeLanguage(_language) {
            this.visible = false;
            localStorage.setItem('language', _language);
            this.set_language({
                language: _language
            });
        }
    },
    watch: {
        ['$store.state.app.store_data']: {
            immediate: true,
            handler: function (val) {
                const lang = localStorage.getItem('language');
                if (val.language_tip_show === 1 && !lang) {
                    this.visible = true;
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.wrap {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    .main {
        margin-top: -100px;
        border: 2px solid var(--themeColor);
        border-radius: 10px;
        width: 80vw;
        padding: 30px;
        box-sizing: border-box;
        text-align: center;
        background-color: white;
        .title-1,
        .title-2 {
            font-family: Arial;
            font-size: 17px;
            font-weight: bold;
        }
        .title-1 {
            margin-top: 20px;
            margin-bottom: 5px;
        }
        .buttons {
            margin-top: 30px;
            margin-bottom: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .button {
                width: 150px;
                border-radius: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                font-weight: bold;
                justify-content: center;
                font-size: 15px;
                border: 2px solid var(--themeColor);
                margin-bottom: 15px;
                &:first-child {
                    border: none;
                    background-color: var(--themeColor);
                    color: white;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        footer {
            text-align: center;
            font-size: 12px;
            padding: 0;
        }
    }
}
</style>
