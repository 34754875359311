<template>
    <div class="category_top_fixed" v-if="visible" id="category_top_fixed">
        <div
            class="item"
            v-for="(cat, i) in menu_dict"
            :class="{ active: currentCat === i }"
            :key="i"
            @click="gotoCategory(i)"
            :id="'category_fixed_item_' + i"
        >
            {{ translate('category', cat.name) }}
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';
const App = createNamespacedHelpers('app');
const Menu = createNamespacedHelpers('menu');

export default {
    name: 'categoryTopFixed',
    components: {},
    data() {
        return {
            visible: false
        };
    },
    computed: {
        ...Menu.mapState(['is_loading', 'menu_dict', 'currentCat']),
        ...mapGetters({
            language: 'app/get_language',
            translate: 'app/translate'
        })
    },
    created() {},
    mounted() {
        window.onscroll = () => {
            const scrollTop = document.documentElement.scrollTop;
            if (scrollTop >= 260) {
                this.visible = true;
            } else {
                this.visible = false;
            }
        };
    },
    methods: {
        ...Menu.mapMutations({
            setCurrentCat: 'SET_CURRENT_CAT'
        }),
        gotoCategory(id) {
            this.setCurrentCat(id);
            this.$nextTick(() => {
                window.scrollTo(0, 300);
            });
        }
    },
    watch: {
        visible(val) {
            if (this.visible) {
                this.$nextTick(() => {
                    const wrapEle = document.getElementById('category_top_fixed');
                    const offset = document.getElementById('category_fixed_item_' + this.currentCat).offsetLeft;
                    wrapEle.scrollTo(offset - 20, 0);
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
.category_top_fixed {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    overflow-x: auto;
    background: var(--themeColor);
    padding: 10px 0px;
    z-index: 999;
    .item {
        padding: 5px 13px;
        word-break: keep-all;
        white-space: nowrap;
        position: relative;
        color: var(--themeColorFont);
        &::after {
            content: '';
            display: block;
            width: 2px;
            height: 14px;
            border-radius: 5px;
            position: absolute;
            top: 50%;
            margin-top: -7px;
            left: 100%;
            margin-left: -1px;
            background: black;
            opacity: 0.4;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        &.active {
            font-weight: bold;
            &::before {
                content: '';
                display: block;
                width: 26px;
                height: 3px;
                border-radius: 20px;
                background-color: var(--themeColorFont);
                position: absolute;
                bottom: -4px;
                left: 50%;
                margin-left: -13px;
            }
        }
    }
}
</style>
