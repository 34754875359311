<template>
    <div>
        <CategoryTopFixed />
        <div class="item-loader" v-if="!itemChanged">
            <img src="../assets/images/item-loading.svg" class="loading-item" v-for="i in loadingItemCount" :key="i" />
        </div>
        <div v-else v-for="(item, index) in menu_dict" :key="index">
            <div v-show="index === currentCat">
                <div class="text-center mt-4" :id="index">
                    <h1>{{ translate('category', item.name) }}</h1>
                    <p class="pb-0" style="color: #3a3a3a; font-size: 14px">
                        {{ translate('menu_desc', item.desc) }}
                    </p>
                </div>
                <div class="menus">
                    <MenuItem
                        class="menu"
                        v-for="product_item in item.list"
                        :key="index + product_item.fid"
                        :product_item="product_item"
                        :language="language"
                        :item="item"
                        :cart_food_dict="cart_food_dict"
                        :is_active="is_active"
                        @choose_food="choose_food"
                        @showOutOfStock="showOutOfStock"
                    />
                </div>
            </div>
        </div>
        <div class="menu-notice" v-if="menuNotice">
            {{ menuNotice }}
        </div>
        <!-- 悬浮购物车 -->
        <!-- <div
            @click="go_to_cart"
            class="float-cart"
            :class="{ floating: is_scrolling && cart_price > 0, bounce: hasNewItem }"
        >
            <span>{{ food_count }}</span>
            <span class="float-price">Cart: ${{ is_vip == 1 ? cart_price_vip : cart_price }}</span>
        </div> -->

        <div>
            <nav style="width: 100%" class="menu-nav" v-bind:class="{ show_menu_nav: if_show_menu_nav }">
                <div class="nav_layer_close" @click="close_nav_layer"></div>
                <div style="padding: 20px 10px">
                    <a
                        @click="choose_menu_nav(index)"
                        v-for="(item, index) in menu_list"
                        v-bind:key="item.mid + '_menu_nav'"
                        class="left-nav-title menu_nav_patch"
                    >
                        <span>{{ translate('category', item.mname) }}</span>
                    </a>
                </div>
            </nav>
        </div>
        <a class="single_button_fixed" @click="go_to_cart" v-if="cart_food_list.length > 0"  data-bee="module_id=button_click&item_id=view_cart">
            {{$t('menu.view_cart', {food_count})}}
        </a>
        <ChooseLang />
        <ReviewLayer />
    </div>
</template>
<style lang="scss" scoped>
.cart_wrap {
    background: none;
    bottom: 60px;
    a {
        border-radius: 15px;
    }
}
.menu-notice {
    padding: 10px;
    padding-bottom: 30px;
    line-height: 1.3;
}
.menu-nav {
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    bottom: 0;
    font-size: 14px;
    overflow-y: auto;
    padding: 0px 0 40px;
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    width: 280px;
    z-index: 10;
    opacity: 0.97;
}

.nav_layer_close {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAWlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////9ZMre9AAAAHXRSTlMAEs76FrJ+bMfv7Mi0qD05BvEhoGBaaVKfYVnzIyC2q60AAAEKSURBVDjLjZRZloMgFERLH444Rk3UpPa/zT7YJIFWG+uL4R7eDBypcdalSKnnUeFITSL8SJJmB9wqc561qutUmxm6uvnE2rNIndejtGA/uURK1n/Mq5pMXULu2OkuX2alPHCgh3C1nvbMcKiM/a/PFWucqGa15YOFOkNUQZOf5O1UnkefmPP8HUgCKBH7iGYcWSLmhtjb0XD2wjDuYrMxYmYGl/EJZByg2cJjPAItNUoqeIxHQLGEsIPHeAQ6yhUkbCjsbowhGHQ4dYtJsd3mJwVwy6i/ZdROGcPNEG6pq42J6by9p/CQXB01a+vJIo38gX1O8PTaj/3r/8+DSXOcqGWIzRcUD4vr2A8eSxYWvARjnwAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 5px;
    background-color: #000;
    border-radius: 50%;
}

.show_menu_nav {
    transform: translateY(0);
    transition-duration: 235ms;
}

.menu_nav_patch {
    margin-top: 10px;
    margin-left: 20px;
    border-bottom: 0.5px solid #eee;
}

.float-cart {
    position: fixed;
    right: 0.75rem;
    bottom: 6rem;
    // color: #000;
    color: var(--themeColorFont);
    font-size: 1rem;
    width: 55px;
    height: 55px;
    text-align: center;
    line-height: 55px;
    vertical-align: middle;
    background: var(--themeColor);
    border-radius: 50%;
    box-shadow: 2px 2px 3px #999;
    cursor: pointer;
    font-family: $boldFont;
}

.float-cart.floating {
    width: 150px;
    border-radius: 27.5px;
}

.float-cart .float-price {
    float: right;
    padding-right: 10px;
    display: none;
}

.float-cart.floating .float-price {
    display: inline;
}

.menu-container-outter {
    background-color: #ffffff;
    margin: 0 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    color: #000;
    font-size: 0.875em;
    padding: 66px 16px 50px;
}

.menu-container-outter .menu-container-inner {
    min-height: 480px;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.category-container {
    margin-bottom: 24px;
    overflow: hidden;
}

.category-container .category-title {
    text-align: center;
    padding: 32px 0 24px 0;
}

.category-container .category-title h2 {
    font-size: 22px;
    font-family: UberMove-Medium, Helvetica, sans-serif;
    color: #262626;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    letter-spacing: -0.02em;
    padding: 0;
}

.need-space {
    margin: 0 7px;
}

.menu-bottom-btn {
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0px;
}

.loading-item {
    width: 100%;
    max-height: 121px;
}

.item-loader {
    margin-top: 30px;
}
</style>
<script>
import { mapState, mapActions, mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';
import MenuItem from '../components/MenuItem';
import ChooseLang from '../components/ChooseLang.vue';
import ReviewLayer from '../components/ReviewLayer.vue';
import CategoryTopFixed from '@/components/CategoryTopFixed.vue';

const FoodStore = createNamespacedHelpers('food');
const Menu = createNamespacedHelpers('menu');
let t1 = 0;
let t2 = 0;
let timer = null;

export default {
    name: 'menu_list',
    components: {
        CategoryTopFixed,
        MenuItem,
        ChooseLang,
        ReviewLayer
    },
    data() {
        return {
            oid: this.$route.query.oid || this.$store.state.app.shared_oid || '',
            scrollTop: 0,
            show_toolbar: true,
            show_car_food_list: false,
            detail_data: {},
            target_food_count: 0,
            temp_id: 0,
            show_picker: false,
            slots: [],
            select_feature_index: -1,
            FOOD_FEATURE_DICT: {},
            picker_name: '',
            waiting_timer: -1,
            is_scrolling: false,
            dataLoaded: false,
            reachedTop: false,
            hasNewItem: false
        };
    },
    async mounted() {
        this.set_app_title('');
    },

    created() {
        if (this.oid) {
            this.initDataOrder({
                oid: this.oid.split('-')[0],
                cartid: this.$store.state.app.shared_cart_id,
                router: this.$router
            });
        }
    },

    beforeMount() {
        if (this.$store.state.app.type === 'preorder' && this.shared_tstatus < 2) {
            this.check_waiting({
                sid: this.sid,
                tid: this.$store.state.app.shared_tid,
                party_type: this.$store.state.app.party_type,
                rank: this.$store.state.app.wait_rank,
                language: this.$store.state.app.store_data.language
            });
            this.waiting_timer = setInterval(() => {
                this.check_waiting({
                    sid: this.$store.state.app.sid,
                    tid: this.$store.state.app.shared_tid,
                    party_type: this.$store.state.app.party_type,
                    rank: this.$store.state.app.wait_rank,
                    language: this.$store.state.app.store_data.language
                });
            }, 7000);
        }
    },
    beforeDestroy() {
        clearInterval(this.waiting_timer);
        this.is_scrolling = false;
        window.removeEventListener('scroll', this.on_scroll, false);

        setTimeout(function () {
            if (window.history.state && window.history.state.key == 'menu_nav') {
                window.history.back();
            }
        }, 100);
    },
    watch: {
        currentCat: {
            immediate: true,
            handler: function (val) {
                if(!val) return;
                window['__logger__'] && window['__logger__']({
                    view: 0,
                    click: 1,
                    module_id: 'category',
                    item_id: this.currentCat
                });        
            }
        },
        shared_tstatus() {
            if (this.shared_tstatus > 1) {
                clearInterval(this.waiting_timer);
            }
        },
        food_count(newCount, oldCount) {
            this.hasNewItem = true;
            if (newCount > oldCount) {
                setTimeout(() => {
                    this.hasNewItem = false;
                }, 2000);
            }
        }
    },
    computed: {
        ...mapState({
            is_loading: (state) => state.menu.is_loading,
            now_menu: (state) => state.menu.now_menu,
            menu_dict: (state) => state.menu.menu_dict,
            menu_list: (state) => state.menu.menu_list,
            food_dict: (state) => state.menu.food_dict,
            show_food_list: (state) => state.menu.show_food_list,
            shared_tid: (state) => state.app.shared_tid,
            show_detail: (state) => state.menu.show_detail,
            sid: (state) => state.app.sid
        }),
        ...mapGetters({
            translate: 'app/translate',
            is_vip: 'app/get_if_is_vip',
            shared_tstatus: 'app/get_shared_tstatus',
            food_count: 'app/get_food_count',
            cart_food_list: 'app/get_cart_food_list',
            cart_price: 'app/get_cart_price',
            cart_price_vip: 'app/get_cart_price_vip',
            cart_food_dict: 'app/get_cart_food_dict',
            language: 'app/get_language',
            shared_oid: 'app/get_shared_oid',
            if_show_menu_nav: 'app/get_if_show_menu_nav'
        }),
        ...Menu.mapState(['currentCat']),
        menuNotice() {
            const { menu_notice, menu_notice_enable } = this.$store.state.app.store_data;
            if (menu_notice_enable && menu_notice !== '') {
                return menu_notice;
            }
            return '';
        },
        itemChanged() {
            return this.menu_list.length > 0;
        },
        content_height() {
            return window.innerHeight - 70;
        },
        is_active: function () {
            return this.menu_dict[this.now_menu].is_active;
        },

        loadingItemCount() {
            const len = Math.round(this.content_height / 121) + 1;
            return new Array(len);
        }
    },
    activated() {
        window.addEventListener('scroll', this.handleScroll);
        if (this.scrollTop > 0) {
            this.$nextTick(function () {
                window.scrollTo(0, this.scrollTop);
            });
        }
    },
    deactivated() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        ...FoodStore.mapActions(['setDetailPageData']),

        handleScroll() {
            let scrollTop = document.documentElement.scrollTop;
            this.scrollTop = scrollTop;
        },

        switchCat(catId) {
            this.currentCat = catId;
        },

        close_nav_layer() {
            setTimeout(function () {
                if (window.history.state && window.history.state.key == 'menu_nav') {
                    window.history.back();
                }
            }, 100);
            this.show_menu_nav(false);
        },
        choose_menu_nav(index) {
            let that = this;
            if (window.history.state && window.history.state.key == 'menu_nav') {
                window.history.back();
            }
            setTimeout(function () {
                let jump = document.querySelectorAll('.category-title');
                let total = jump[index].offsetTop - 70;
                // Chrome
                document.body.scrollTop = total;
                // Firefox
                document.documentElement.scrollTop = total;
                // Safari
                window.pageYOffset = total;
                that.show_menu_nav(false);
            }, 100);
        },
        go_to_cart(e) {
            if (this.food_count > 0) {
                this.$router.push({ path: '/sub/cart' });
            } else {
                const msg = this.$t('menu.cart_empty');
                this.set_msg({ msg });
            }
        },
        hide_food_list(e) {
            this.show_car_food_list = false;
        },
        show_list_zone(e) {
            if (this.cart_price > 0) {
                this.show_car_food_list = true;
            }
        },
        empty_cart(e) {
            this.clear_cart();
        },
        goto_cart(e) {
            this.$router.push({ path: '/sub/cart' });
        },
        close_layer(e) {
            this.doToggleDetail(false);
        },
        choose_food(data) {
            const { food_data, is_out_of_stock, is_active } = data;
            if (!is_active || is_out_of_stock == 1) {
                return;
            }
            let _food_data = JSON.parse(JSON.stringify(food_data));

            if (!_food_data.fprice) {
                return;
            }

            if (this.FOOD_FEATURE_DICT[_food_data.fid]) {
                _food_data.feature_default_value = this.FOOD_FEATURE_DICT[_food_data.fid];
            }

            for (let i = 0; i < this.cart_food_list.length; i++) {
                if (
                    this.cart_food_list[i].fid == _food_data.fid &&
                    this.cart_food_list[i].feature_default_value == _food_data.feature_default_value &&
                    this.cart_food_list[i].uname == this.$store.state.app.uname
                ) {
                    _food_data.fcount = parseInt(this.cart_food_list[i].fcount);
                }
            }

            this.target_food_count =
                (this.cart_food_dict[food_data.fid] && this.cart_food_dict[food_data.fid].mix_fcount) || 0;
            this.detail_data = _food_data;
            // this.doToggleDetail(true);
            this.setDetailPageData({
                detail_data: this.detail_data,
                mix_fcount: this.target_food_count,
                from: 'menu'
            });

            this.$router.push('/food');
        },
        choose_menu(_mid) {
            this.setFoodsData(_mid);
        },
        doAdd(food_data) {
            let temp_count = (this.cart_food_dict[food_data.fid] && this.cart_food_dict[food_data.fid].mix_fcount) || 0;
            let _food_data = JSON.parse(JSON.stringify(food_data));
            _food_data.fcount = 1;

            if (this.FOOD_FEATURE_DICT[_food_data.fid]) {
                _food_data.feature_default_value = this.FOOD_FEATURE_DICT[_food_data.fid];
            }

            for (let i = 0; i < this.cart_food_list.length; i++) {
                if (
                    this.cart_food_list[i].fid == _food_data.fid &&
                    this.cart_food_list[i].feature_default_value == _food_data.feature_default_value &&
                    this.cart_food_list[i].uname == this.$store.state.app.uname
                ) {
                    _food_data.fcount = parseInt(this.cart_food_list[i].fcount) + 1;
                }
            }

            this.edit_food({
                cartid: this.$store.state.app.shared_cart_id,
                fcount: temp_count + 1,
                tid: this.$store.state.app.shared_tid,
                uid: this.$store.state.app.uid,
                uname: this.$store.state.app.uname,
                food_data: _food_data,
                type: 'add',
                router: this.$router
            });
        },
        doMinus(food_data) {
            let temp_count = (this.cart_food_dict[food_data.fid] && this.cart_food_dict[food_data.fid].mix_fcount) || 0;
            let _food_data = JSON.parse(JSON.stringify(food_data));
            _food_data.fcount = 1;

            if (this.FOOD_FEATURE_DICT[_food_data.fid]) {
                _food_data.feature_default_value = this.FOOD_FEATURE_DICT[_food_data.fid];
            }

            for (let i = 0; i < this.cart_food_list.length; i++) {
                if (
                    this.cart_food_list[i].fid == _food_data.fid &&
                    this.cart_food_list[i].feature_default_value == _food_data.feature_default_value &&
                    this.cart_food_list[i].uname == this.$store.state.app.uname
                ) {
                    _food_data.fcount = parseInt(this.cart_food_list[i].fcount) - 1;
                }
            }

            this.edit_food({
                cartid: this.$store.state.app.shared_cart_id,
                fcount: temp_count - 1,
                tid: this.$store.state.app.shared_tid,
                uid: this.$store.state.app.uid,
                uname: this.$store.state.app.uname,
                food_data: _food_data,
                type: 'minus',
                router: this.$router
            });
        },
        ...mapActions({
            initDataOrder: 'sharedorderdetail/initData',
            initData: 'menu/initData',
            edit_food: 'app/edit_cart_food',
            doToggleDetail: 'menu/doToggleDetail',
            check_waiting: 'app/checkWaiting',
            set_msg: 'app/setToast'
        }),
        ...mapMutations({
            setFoodsData: 'menu/SET_FOODS_DATA',
            add_food: 'app/APP_ADD_FOOD_INTO_CART',
            del_food: 'app/APP_DEL_FOOD_INTO_CART',
            set_app_title: 'app/APP_SET_APP_TITLE',
            clear_cart: 'app/APP_CLEAR_CART_DATA',
            show_menu_nav: 'app/APP_SHOW_MENU_NAV'
        }),
        showOutOfStock() {
            this.set_msg({ msg: this.$t('food.outof_stock') });
        },
        go_to_order(e) {
            this.$router.push({ path: '/sub/sharedorderdetail' });
        },
        go_to_party(e) {
            this.$router.push({ path: '/sub/party?oid=' + this.shared_oid });
        },
        showCategory() {
            let that = this;
            window.history.pushState({ key: 'menu_nav' }, 'Menu nav', '/#/catagories');
            window.addEventListener(
                'popstate',
                function (e) {
                    that.show_menu_nav(false);
                },
                false
            );
            this.show_menu_nav(true);
        }
    }
};
</script>
